import React from "react";
import PropTypes from "prop-types";

const size = ({size}) => size || "16px";
const color = ({color}) => color || "#272727";
const margin = ({margin}) => margin || "0px";

export const FiltersIcon = props => {
  const styles = {
    transform: "rotateZ(90deg)",
    minWidth: size(props),
    minHeight: size(props),
    color: color(props),
    width: size(props),
    height: size(props),
    margin: margin(props),
  };
  return (
    <svg
      style={styles}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 6V4m0 2a2 2 0 1 0 0 4m0-4a2 2 0 1 1 0 4m-6 8a2 2 0 1 0 0-4m0 4a2 2 0 1 1 0-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 1 0 0-4m0 4a2 2 0 1 1 0-4m0 4v2m0-6V4"
      />
    </svg>
  );
};

FiltersIcon.propTypes = {
  size: PropTypes.string,
  margin: PropTypes.string,
  color: PropTypes.string,
};

export default FiltersIcon;
